export const environment: any = {
    version: require('../../package.json').version,
    VERSION: require('../../package.json').version,
    API_ENDPOINT: 'https://pen-api.elements-eng.cloud:46443/api/v1',
    API_URL: 'https://pen-api.elements-eng.cloud:46443/api/v1/datastructures',
    API_STATIC_FILE_UPLOAD: 'https://eng-upload.q9elements.com:11443/api',
    APPNAME: 'HD-APP',
    ANALYSIS_API: 'https://dev-gateway.q9elements.com/automation-us-dependency-analysis/api/v1/dependency-analysis',
    CHANGELOG_APP_URL: 'https://pen-changelogs.elements-eng.cloud',
    CLOUD_NAME: 'elements',
    CLOUD_TYPE: 'public',
    DATA_STRUCTURE_URL: 'https://pen-datatable.elements-eng.cloud',
    DE_APP_URL: 'https://pen-diagram.elements-eng.cloud',
    DE_APP: 'https://pen-diagram.elements-eng.cloud',
    DIAGRAM_URL: 'https://pen-diagram.elements-eng.cloud',
    DOMAIN: 'elements-eng.cloud',
    ENTERPRISE_URL: 'https://pen-corp.elements-eng.cloud',
    ENV: 'pen',
    GDPR_SUPPORT_LINK: 'https://support.elements.cloud/en/articles/1221094-enable-data-privacy-gdpr-compliance-for-a-ref-model',
    HELP_LINK: 'https://support.elements.cloud/',
    IMPORT_MAP_API: 'https://dev-gateway.q9elements.com/pen-map-import/api/v1',
    IMPORTS_API: 'https://dev-gateway.q9elements.com/pen-import/api/v1',
    INTERCOM_APP_ID: 'sfi4u1en',
    INTERCOM_URL: 'string',
    MAIN_APP_URL: 'https://pen.elements-eng.cloud',
    MAIN_APP: 'https://pen.elements-eng.cloud',
    MAP_MANAGEMENT_APP: 'https://pen-mapmgt.elements-eng.cloud',
    NO_IMAGE_URL: '../../assets/images/no_image.png',
    NOUI: false,
    PARTNER_APP: 'https://pen-partnermgt.elements-eng.cloud',
    PREVIEW_HOLDER: '/assets/images/map_placeholder.png',
    production: true,
    PX_KEY: 'AP-3EN4L67VSTXG-2-3',
    RAVEN_DSN: 'https://eb6892047d9b42638a7563ddb26386ea@sentry.io/1233470',
    RAVEN_ID: '9e76fe14aaba4a31846fe607572f9eb1',
    REF_MODEL_APP: 'https://pen-refmodel.elements-eng.cloud',
    RELEASE_URL: 'https://pen-releasemgt.elements-eng.cloud',
    REMOTE_UNLOCK_TTL: 3600000,
    REPORTS_API_ENDPOINT: 'https://dev-gateway.q9elements.com/pen-report/api/v1',
    REPORTS_API: 'https://dev-gateway.q9elements.com/pen-report/api/v1', 
    REPORTS_APP: 'https://pen-report.elements-eng.cloud',
    SALESFORCE_CLIENT_ID: '3MVG9XLfsJ3MQLvwvVWbppMl4vlaCVmzU2cZ.5TgxlfR4KInGlwKQBENWCLo2MNp0HGID6JEnp6Jcj_sLa8cu',
    SALESFORCE_REDIRECT_URL: 'https://pen.elements-eng.cloud/auth/callback',
    SALESFORCE_URL_TEST: 'https://test.salesforce.com/services/oauth2/authorize',
    SALESFORCE_URL: 'https://login.salesforce.com/services/oauth2/authorize',
    SECURE_COOKIES: true,
    SYS_ADMIN_APP: 'https://pen-sysadmin.elements-eng.cloud',
    TEAM_APP: 'https://pen-spacemgt.elements-eng.cloud',
    TOASTR_TIMEOUT: 5000,
    UNLOCK_TTL: 180000,
    STATIC_FILES_URL: 'https://static.elements.cloud',
    fbConfig: {
        apiKey:'AIzaSyCTc-0zZIGzxkjeOm7RkgN6hkW55XVFjwM',
        authDomain:'elements-pen-hrig.firebaseapp.com',
        databaseURL: 'https://elements-pen-hrig-default-rtdb.firebaseio.com',
        projectId: 'elements-pen-hrig',
        appId: '1:918256457256:web:043816b0c7caa94b6a471d'
    }
}; 
